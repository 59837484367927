import { SvgIcon } from "@progress/kendo-react-common";
import type { Dispatch, SetStateAction } from "react";
import { tooltipIcon } from "../../../../../../common/models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { CustomerInvoiceType } from "../../../../../../common/models/src/lib/enums/customer.enum";
import type { GeneratedInvoiceResponse } from "../../../../../../common/models/src/lib/interfaces/invoice.interface";
import { CelerumTooltip } from "../../../../../../common/ui/src/lib/components/celerum-tooltip/celerum-tooltip.component";
import { getFormattedCurrency } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "./generated-invoice-modal-content.module.scss";

interface GeneratedInvoiceModalContentProps {
	invoices: GeneratedInvoiceResponse[];
	setPostingDate: Dispatch<SetStateAction<Date>>;
	postingDate: Date;
}

export const GeneratedInvoiceModalContent = ({
	invoices,
}: GeneratedInvoiceModalContentProps) => {
	return (
		<div className={styles.container}>
			<table className={styles.table}>
				<thead>
					<tr className={styles.table__thead}>
						<th className={styles.table__headerCell}>Customer</th>
						<th className={styles.table__headerCell}>Job Number</th>
						<th className={styles.table__headerCell}>Invoice Number</th>
						<th className={styles.table__headerCell}>Invoice Total</th>
						<th className={styles.table__headerCell}>Date</th>
						<th className={styles.table__headerCell}>Errors</th>
					</tr>
				</thead>
				<tbody>
					{invoices.map((item) => (
						<tr key={item.postingDate} className={styles.table__row}>
							<td className={styles.table__dataCell}>
								{item?.customer?.name ?? NOT_AVAILABLE}
							</td>
							<td className={styles.table__dataCell}>
								{item?.invoices.map((item) => (
									<div
										key={item.id}
										className={styles.table__dataCell}
										style={
											item.isFailed ? { color: "red" } : { color: "black" }
										}
									>
										{item.uniqueId}
									</div>
								)) ?? NOT_AVAILABLE}
							</td>
							<td>{item?.invoices[0]?.invoiceNumber ?? NOT_AVAILABLE}</td>
							<td className={styles.table__dataCell}>
								{item?.customer?.invoiceType ===
								CustomerInvoiceType.MULTIPLE_INVOICE ? (
									item?.invoices.map((value) => (
										<div key={value.id} className={styles.table__dataCell}>
											{getFormattedCurrency(
												value.grossSum,
												item?.customer?.currency?.code,
											)}
										</div>
									)) ?? NOT_AVAILABLE
								) : (
									<div>
										{item?.invoices
											? getFormattedCurrency(
													item?.invoices?.reduce(
														(accumulator, currentValue) => {
															return accumulator + currentValue.grossSum;
														},
														0,
													),
													item?.customer?.currency?.code,
												)
											: NOT_AVAILABLE}
									</div>
								)}
							</td>
							<td className={styles.table__dataCell}>
								{item.postingDate
									? formatDate(new Date(item.postingDate))
									: NOT_AVAILABLE}
							</td>
							<td className={styles.table__dataCell}>
								<div style={{ width: "25px" }}>
									{item?.invoices.map((invoice) => {
										if (!(invoice?.failureReasons.length ?? 0 < 1))
											return NOT_AVAILABLE;
										return (
											<CelerumTooltip
												key={invoice.id}
												title={invoice.failureReasons.toString()}
											>
												<SvgIcon width={25} icon={tooltipIcon} />
											</CelerumTooltip>
										);
									})}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
