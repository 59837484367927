import { SvgIcon } from "@progress/kendo-react-common";
import {
	arrowIcon,
	customerIcon,
	deliveryIconRounded,
	phoneIcon,
	trailerIcon,
	truckIcon,
} from "../../../../../../../../common/models/src/lib/constants/icon.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import {
	ABSENT,
	NOT_AVAILABLE,
} from "../../../../../../../../common/models/src/lib/constants/messages.constants";
import { LegType } from "../../../../../../../../common/models/src/lib/enums/leg.enum";
import type { IOperationLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import { CelerumAddressCard } from "../../../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { formatDate } from "../../../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "../../leg-accordion-content.module.scss";
import { TitleSection } from "../title-section/title-section.component";

export const OperationInformation = ({ leg }: { leg: IOperationLeg }) => {
	const {
		collectionLocation,
		collectionDate,
		deliveryLocation,
		deliveryDate,
		truck,
		truckType,
		trailer,
		trailerType,
		driver,
		subcontractor,
	} = leg;

	const getTitleSectionValue = (): string => {
		switch (leg.type) {
			case LegType.Collection:
				return "Collection";
			case LegType.Delivery:
				return "Delivery";
			case LegType.Segment:
				return "Segment";
			case LegType.CollectDeliver:
				return "Collect Deliver";
			case LegType.ContainerPickUp:
				return "Container Pick Up";
			case LegType.ContainerDropOff:
				return "Container Drop Off";
			case LegType.ContainerGoodsCollection:
				return "Container Goods Collection";
			case LegType.ContainerGoodsDelivery:
				return "Container Goods Delivery";
			case LegType.LoadAndLash:
				return "Load And Lash";
			case LegType.Devan:
				return "Devan";
			default:
				return "";
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<TitleSection
					icon={truckIcon}
					title="Leg type"
					value={getTitleSectionValue()}
				/>
				{subcontractor && (
					<>
						<TitleSection
							icon={customerIcon}
							title="Subcontractor"
							value={subcontractor.name ?? ABSENT}
						/>
						<TitleSection
							icon={phoneIcon}
							title="Subcontractor phone"
							value={
								subcontractor.phones?.length
									? subcontractor.phones[0] ?? ABSENT
									: ABSENT
							}
						/>
					</>
				)}
				<TitleSection
					icon={truckIcon}
					title="Truck type"
					value={truckType?.name ?? ABSENT}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice"
					value={
						leg?.supplierInvoice !== undefined
							? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? ABSENT
							: ABSENT
					}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice Number"
					value={
						leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
							? leg?.supplierInvoiceNumber
							: ABSENT
					}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice Date"
					value={
						leg?.supplierInvoiceDate
							? formatDate(new Date(leg.supplierInvoiceDate))
							: ABSENT
					}
				/>
			</div>
			<div className={styles.wrapper}>
				{driver && (
					<>
						<TitleSection
							icon={customerIcon}
							title="Driver"
							value={`${driver.name}` ?? ABSENT}
						/>
						<TitleSection
							icon={phoneIcon}
							title="Driver phone"
							value={driver.phones.length ? driver.phones[0] ?? ABSENT : ABSENT}
						/>
					</>
				)}
				<TitleSection
					icon={trailerIcon}
					title="Trailer type"
					value={trailerType?.name ?? ABSENT}
				/>
				<TitleSection
					icon={trailerIcon}
					title="Trailer"
					value={trailer?.name ?? ABSENT}
				/>
				<TitleSection
					icon={truckIcon}
					title="Truck"
					value={truck?.name ?? ABSENT}
				/>
			</div>
			<div className={styles.wrapper}>
				<CelerumAddressCard
					title="Collection"
					name={collectionLocation?.name ?? NOT_AVAILABLE}
					address={collectionLocation?.address ?? NOT_AVAILABLE}
					date={
						collectionDate
							? formatDate(new Date(collectionDate))
							: NOT_AVAILABLE
					}
				/>
				<SvgIcon icon={arrowIcon} width={90} />
				<CelerumAddressCard
					title="Delivery"
					name={deliveryLocation?.name ?? NOT_AVAILABLE}
					address={deliveryLocation?.address ?? NOT_AVAILABLE}
					date={
						deliveryDate ? formatDate(new Date(deliveryDate)) : NOT_AVAILABLE
					}
					icon={deliveryIconRounded}
				/>
			</div>
		</div>
	);
};
