import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import type { Dispatch, SetStateAction } from "react";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { CustomerInvoiceType } from "../../../../../../common/models/src/lib/enums/customer.enum";
import type { IJobCost } from "../../../../../../common/models/src/lib/interfaces/invoice.interface";
import { getFormattedCurrency } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import type { RenderedInvoices } from "../../invoices-feature/invoices-feature";
import styles from "./selected-invoices-modal-content.module.scss";

interface InvoiceCustomersModalContentProps {
	selectedInvoices: RenderedInvoices[];
	setPostingDate: Dispatch<SetStateAction<Date>>;
	postingDate: Date;
}

export const InvoiceCustomersModalContent = ({
	selectedInvoices,
	setPostingDate,
	postingDate,
}: InvoiceCustomersModalContentProps) => {
	return (
		<div>
			<div className={styles.container}>
				<table className={styles.table}>
					<thead>
						<tr className={styles.table__thead}>
							<th className={styles.table__headerCell}>Customer</th>
							<th className={styles.table__headerCell}>Job Number</th>
							<th className={styles.table__headerCell}>Invoice Total</th>
						</tr>
					</thead>
					<tbody>
						{selectedInvoices.map((item) => (
							<tr key={item.id} className={styles.table__row}>
								<td className={styles.table__dataCell}>
									{item?.customerName ?? NOT_AVAILABLE}
								</td>
								<td className={styles.table__dataCell}>
									{item?.uniqueIdList.map((item: IJobCost) => (
										<div key={item.id} className={styles.table__dataCell}>
											{item.uniqueId}
										</div>
									)) ?? NOT_AVAILABLE}
								</td>
								<td className={styles.table__dataCell}>
									{item?.customer?.invoiceType ===
									CustomerInvoiceType.MULTIPLE_INVOICE ? (
										item?.uniqueIdList.map((value: IJobCost) => (
											<div key={value.id} className={styles.table__dataCell}>
												{getFormattedCurrency(
													value.cost,
													item?.customerCurrencyCode,
												)}
											</div>
										)) ?? NOT_AVAILABLE
									) : (
										<div>
											{item?.uniqueIdList
												? getFormattedCurrency(
														item?.uniqueIdList?.reduce(
															(accumulator, currentValue) => {
																return accumulator + currentValue.cost;
															},
															0,
														),
														item?.customerCurrencyCode,
													)
												: NOT_AVAILABLE}
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div>
				<div className={styles.footerSubtitle}>
					<span>
						Please select the posting date before confirming the invoice:
					</span>
				</div>
				<FieldWrapper className={styles.inputContainer}>
					<Label className={styles.label}>
						Posting Date <span className={styles.label__required}>*</span>
					</Label>
					<DatePicker
						onChange={(event) => {
							setPostingDate(event.target.value as Date);
						}}
						value={postingDate}
						format="dd/MM/yyyy"
						className={styles.input}
					/>
				</FieldWrapper>
			</div>
		</div>
	);
};
