import { SvgIcon } from "@progress/kendo-react-common";
import { Checkbox } from "@progress/kendo-react-inputs";
import type { Align } from "@progress/kendo-react-popup";
import { filterIcon } from "@progress/kendo-svg-icons";
import { useEffect, useRef, useState } from "react";
import type {
	IBase,
	IBaseWithIdString,
} from "../../../../../../../models/src/lib/interfaces/base.interface";
import type { ICurrency } from "../../../../../../../models/src/lib/interfaces/currency.interface";
import type { IFilterItem } from "../../../../../../../models/src/lib/interfaces/filter.interface";
import {
	CelerumButton,
	CelerumWhiteButton,
} from "../../../celerum-buttons/celerum-buttons.component";
import { CelerumPopup } from "../../celerum-popup.component";
import styles from "./celerum-popup-checkbox-selection.module.scss";

interface CelerumPopupCheckboxSelectionProps {
	show: boolean | undefined;
	anchor: React.RefObject<HTMLElement>;
	filterTitle: string;
	popupAlign?: Align;
	values: IBase[] | ICurrency[] | IBaseWithIdString[];
	handleSubmit: (ids: (number | string)[]) => void;
	initialValues: IFilterItem[];
	onClose?: () => void;
}

export const CelerumPopupCheckboxSelection = ({
	anchor,
	show,
	filterTitle,
	values,
	popupAlign,
	handleSubmit,
	onClose,
	initialValues,
}: CelerumPopupCheckboxSelectionProps) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [selectedIds, setSelectedIds] = useState<(number | string)[]>(
		initialValues?.length ? initialValues[0]?.value ?? [] : [],
	);

	const handleSelection = (id: number | string) => {
		if (
			selectedIds.find((item: number | string) => item === id) === undefined
		) {
			setSelectedIds((prev) => [...prev, id]);
		} else {
			setSelectedIds(
				selectedIds.filter((item: number | string) => item !== id),
			);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				onClose &&
				containerRef.current &&
				!containerRef.current.contains(event.target as Node) &&
				anchor.current &&
				!anchor.current.contains(event.target as Node)
			) {
				onClose();
				setSelectedIds(
					initialValues?.length ? initialValues[0]?.value ?? [] : [],
				);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [anchor, initialValues, onClose]);

	return (
		<CelerumPopup
			className={styles.container}
			anchor={anchor}
			show={show}
			popupAlign={popupAlign}
		>
			<div ref={containerRef}>
				<div className={styles.header}>
					<SvgIcon icon={filterIcon} />
					<p className={styles.title}>{filterTitle}</p>
				</div>
				<div className={styles.checkboxContainer}>
					{values.map((item: IBase | ICurrency | IBaseWithIdString) => (
						<div key={item.id} className={styles.checkboxElement}>
							<Checkbox
								size={"large"}
								label={"name" in item ? item.name : item.code}
								checked={
									selectedIds.find((id: number | string) => id === item.id) !==
									undefined
								}
								onClick={() => {
									handleSelection(item.id);
								}}
							/>
						</div>
					))}
				</div>
				<div className={styles.buttonsContainer}>
					<CelerumWhiteButton
						title="Clear"
						onClick={() => {
							setSelectedIds([]);
							handleSubmit([]);
						}}
					/>
					<CelerumButton
						title="Filter"
						onClick={() => {
							handleSubmit(selectedIds);
						}}
					/>
				</div>
			</div>
		</CelerumPopup>
	);
};
